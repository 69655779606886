import '../App.css';
import Address from './Address.js';

function Footer() {
  return (
    <div className='Footer'>
      <Address />
      <div className='Footer-copyright'>
        <span className='Copyright-text'>
          כל הזכויות שמורות | ד״ר אפרת פלייסיג
        </span>
      </div>
    </div>
  );
}

export default Footer;
