import React from "react";
import {createRoot} from 'react-dom/client';
import './index.css';
// import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Home from "./components/Home.jsx";
import Clinic from "./components/Clinic.jsx";
import MedicalAreas from "./components/MedicalAreas";
import Papers from "./components/Papers.jsx";
import Contact from "./components/Contact.jsx";
import English from "./components/English.jsx";

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Router>
    <Header />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/home" element={<Home />} />
      <Route path="/clinic" element={<Clinic />} />
      <Route path="/medical-areas" element={<MedicalAreas />} />
      <Route path="/papers" element={<Papers />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/english" element={<English />} />
    </Routes>
    <Footer />
  </Router>

);

// serviceWorker.unregister();