import '../App.css';
import ToSchedule from './ToSchedule.jsx';

document.onreadystatechange = () => {
  if (document.readyState === 'complete') {
    
// Get the modal
var modal = document.getElementById("myModal");

// Get the button that opens the modal
var btn = document.getElementById("myBtn");

// Get the <span> element that closes the modal
var span = document.getElementsByClassName("close")[0];

// When the user clicks the button, open the modal 
btn.onclick = function() {
  modal.style.display = "block";
}

// When the user clicks on <span> (x), close the modal
span.onclick = function() {
  modal.style.display = "none";
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
  if (event.target == modal) {
    modal.style.display = "none";
  }
}
  }
};



function Address() {
  return (
    <div className='Subscribe'>

      <button id="myBtn" className="accessibility_button">הצהרת נגישות</button>

      <div id="myModal" className="modal">

        <div className="modal-content">
          <span className="close">&times;</span>
          <p dir="rtl"><strong>הצהרת נגישות</strong></p>
          <p dir="rtl">הצהרת הנגישות עודכנה בתאריך [3/9/2023]</p>
          <p dir="rtl">מרפאתה של ד״ר אפרת פלייסיג מעניקה שירותי רפואת עיניים לכלל האוכלוסיה. אנו פועלים רבות על מנת להנגיש את המרפאה ואת אתר האינטרנט שלנו לאנשים עם מוגבלות על מנת לקדם שוויון זכויות ושקיפות כלפי אנשים עם מוגבלות.</p>
          <p dir="rtl">אתר אינטרנט נגיש, הינו אתר המאפשר לאדם עם מוגבלות, לגלוש באותה רמת יעילות והנאה כגולשים אחרים, תוך שימוש ביכולות המערכת עליה הוא פועל ובאמצעות טכנולוגיות מסייעות לנגישות&nbsp;.</p>
          <p dir="rtl">התאמות הנגישות באתר בוצעו בהתאם לסימן ג&apos;: שירותי האינטרנט&nbsp;בתקנות שוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות) התשע&quot;ג 2013, לתקן הישראלי ת&quot;י 5568 המבוסס על הנחיותWCAG 2.0&nbsp;, האתר הונגש לרמה&nbsp;AA&nbsp;ובכפוף לשינויים והתאמות שבוצעו במסמך התקן הישראלי.</p>
          <p dir="rtl">האתר נבדק כל 6 חודשים כדי&nbsp;להבטיח את תחזוקת נגישות האתר. &nbsp;</p>
          <p dir="rtl">באתר אינטרנט זה, ניתן לגלוש בצורה מיטבית ונגישה באמצעות הדפדפנים הנפוצים ומומלץ להשתמש בדפדפנים הבאים:&nbsp;&nbsp;Firefox / Opera / Safari / Lynx]/ [Edge / Chrome ובתוכנות קוראות מסך [NVDA / Jaws/ Voiceover].</p>
          <p dir="rtl">הסדרי נגישות:</p>
          <p dir="rtl"><strong>מרפאת רחובות</strong> &ndash; דרך ירושלים 15, מרכז מסחרי, קומה 1, קיימת חניית נכים במגרש החניה, קיים רצף גישה מהחניה דרך הכניסה ועד המרפאה (כולל מעלית). קיימים שירותי נכים נגישים בבנין. קיימים שלטי זיהוי והכוונה. מותרת כניסה עם חיות שירות המיועדות לסייע לאדם עם מוגבלות.&nbsp;</p>
          <p dir="rtl"><strong>מרפאת חולון</strong> &ndash; רבינוביץ 11 מרכז סאדאב, קומה 1, קיימת חניית נכים במגרש החניה, קיים רצף גישה מהחניה דרך הכניסה ועד המרפאה (כולל מעלית). קיימים שירותי נכים נגישים בבנין. קיימים שלטי זיהוי והכוונה. מותרת כניסה עם חיות שירות המיועדות לסייע לאדם עם מוגבלות.</p>
          <p dir="rtl"><strong>מרפאת אשדוד&nbsp;</strong>&ndash; כנרת 7 בית הרופאים, קומה 2, קיימת חניית נכים במגרש החניה, קיים רצף גישה מהחניה דרך הכניסה ועד המרפאה (כולל מעלית). קיימים שירותי נכים נגישים בבנין. קיימים שלטי זיהוי והכוונה. מותרת כניסה עם חיות שירות המיועדות לסייע לאדם עם מוגבלות.</p>
          <p dir="rtl">אנו ממשיכים במאמצים לשפר את נגישות האתר והמרפאה כחלק ממחויבותנו לאפשר לכלל האוכלוסיה כולל אנשים עם מוגבלויות לקבל את השרות הנגיש ביותר.&nbsp;</p>
          <p dir="rtl">במידה ומצאתם באתר האינטרנט בעיה בנושא הנגישות או שהנכם זקוקים עזרה, אתם מוזמנים לפנות אלינו דרך רכז הנגישות של הארגון ונעשה כל מאמץ למצוא עבורך פתרון מתאים ולטפל בתקלה בהקדם ככל הניתן.&nbsp;</p>
          <p dir="rtl">רכז הנגישות במרפאה: יורם פלייסיג &ndash; טלפון 0523885054, כתובת מייל <a href="mailto:dr-efrat@gmail.com">dr-efrat@gmail.com</a></p>
          <p dir="rtl">אחראית הנגישות במרפאה: ד״ר אפרת פלייסיג &ndash; טלפון 0523885054, כתובת מייל <a href="mailto:dr-efrat@gmail.com">dr-efrat@gmail.com</a></p>
          <p dir="rtl"><br /></p>
        </div>

      </div>
      <ToSchedule />
      <div className='address-text'>
        מרפאת רחובות - דרך ירושלים 15
        <br />
        מרפאת חולון - רבינוביץ׳ 11, מרכז סאדאב
        <br />
        מרפאת אשדוד - כנרת 7, בית הרופאים
        </div>
    </div>
  );
}

export default Address;