import React from "react";
import strings from "../strings.js"
import Efrat from "../images/Efrat.jpg"
import eye from "../images/eye.png"

function English() {
  return (
    <div className="Page">

      <div className="Text-and-image Reverse-column-mobile BackgroundImage">
        <div style={{marginLeft: '10%'}}>
          <div className="Primary-text">
            Efrat Fleissig MD
          </div>
          <div className="Secondary-text">
          Ophthalmology
          </div>
        </div>
        <img className="Image" src={Efrat} alt={"Efrat"} />
      </div>

      <div className="Text-and-image Reverse-column-mobile">
        <div style={{marginLeft: '10%', marginRight: '10%'}}>
          <p className="Secondary-text" style={{color: 'black', fontSize: '1.5em', lineHeight: 2}}>
          Dr. Efrat Fleissig is an ophthalmologist at Assuta Hashalom medical center.
          She received her medical degree from the Hebrew University School of Medicine, and completed her training in ophthalmology in Tel-Aviv Sourasky Medical Center - Ichilov Hospital.
          Dr. Fleissig completed two fellowship programs in the university of Louisville in Kentucky training in retinal diseases and uveitis in the United States of America.
          Dr. Fleissig published scientific papers in the leading ophthalmology journals, and she is lecturing in Israel and abroad.
          <br />
              <img className="Image" src={eye} alt={"Efrat"} style={{margin: '2%'}} />
          <br />
          <b>Dr. Fleissig's office:</b>
          <br />
          15 Derech Jerusalem, Rehovot
          <br />
          11 Rabinowitz St - Sadab Center, Holon
          <br />
          7 Kineret St, Ashdod
          <br />
          <b>Tel: {strings.phone}</b>
          </p>
        </div>
      </div>

  </div>
  );
}

export default English;