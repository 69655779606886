import React from "react";
import Efrat from "../images/Efrat.jpg"
import eyechart from "../images/eyechart.png"

function Home() {
  return (
    <div className="Page">

      <div className="Text-and-image Reverse-column-mobile BackgroundImage">
        <div style={{marginLeft: '10%'}}>
          <div className="Primary-text">
          ד״ר אפרת פלייסיג
          </div>
          <div className="Secondary-text">
          מומחית רשתית ומחלות עיניים
          </div>
        </div>
        <img className="Image" src={Efrat} alt={"Efrat"} />
      </div>

      <div className="Text-and-image Reverse-column-mobile">
        <img className="Image" src={eyechart} alt={"Efrat"} />
        <div style={{marginLeft: '10%'}}>
          <p className="Secondary-text" style={{color: 'black', fontSize: '1.5em', marginRight: '25%', lineHeight: 2}}>
          ד״ר אפרת פלייסיג משמשת כרופאה בכירה במרפאות רשתית בבית החולים אסותא השלום.
ד״ר אפרת פלייסיג סיימה את לימודי הרפואה באוניברסיטה העברית והדסה בירושלים, והמשיכה
להתמחות במחלקת עיניים בבית החולים איכילוב בתל אביב, וסיימה את בחינות ההתמחות בהצטיינות.
לאחר סיום ההתמחות, עברה במשך 3 שנים שתי התמחויות-על <span dir="ltr" lang="en">(fellowship)</span> בארה״ב באוניברסיטת לואיוויל בקנטאקי בניתוחי רשתית
ומחלות ענביה <span dir="ltr" lang="en">(uveitis)</span>. 
ד״ר אפרת פלייסיג פרסמה מאמרים בכתבי העת המובילים ברפואת עיניים ומרצה בכנסים בארץ ובחו״ל
          </p>
        </div>
      </div>

  </div>
  );
}

export default Home;