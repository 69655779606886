import React from "react";
import strings from "../strings.js"
import Efrat from "../images/Efrat.jpg"
import xx from "../images/xx.jpg"

function Contact() {
  return (

<div className="Page">
<div className="Text-and-image Reverse-column-mobile BackgroundImage">
    <div style={{marginLeft: '10%'}}>
      <div className="Primary-text">
      יצירת קשר
      </div>
    </div>
    <img className="Image" src={Efrat} alt={"Efrat"} />
  </div>

  <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center'}}>
    <img className="Image" src={xx} alt={"Device"} />
      <p className="Secondary-text" style={{color: 'black', textAlign: 'right', fontSize: '2em', lineHeight: 2, marginBottom: '7%'}}>
      :ליצירת קשר וקביעת תור
      <br />
      {strings.phone}
      </p>
  </div>

</div>
  );
}

export default Contact;