import '../App.css';
import { NavLink } from "react-router-dom";


function Navbar() {
  return (
    <div className='Navbar'>
      <NavLink className="Nav-link" to="/english">
          English🇺🇸
        </NavLink>
      <NavLink className="Nav-link" to="/contact">
          יצירת קשר
        </NavLink>
      <NavLink className="Nav-link" to="/papers">
          מאמרים
        </NavLink>
        <NavLink className="Nav-link" to="/medical-areas">
          תחומי טיפול
        </NavLink>
        <NavLink className="Nav-link" to="/clinic">
          מרפאות
        </NavLink>
        <NavLink className="Nav-link" to="/home">
          בית
        </NavLink>
    </div>
  );
}

export default Navbar;
