import '../App.css';
import logo from "../images/logo.jpg"

function LogoAndTagline() {
  return (
    <div className='Nav-link' style={{display: 'flex', flexDirection: 'row'}}>
        <img className="Image" src={logo} alt={"logo"} style={{width: 235, height: 235}} />
    </div>
  );
}

export default LogoAndTagline;
