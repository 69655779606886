import React from "react";
import strings from "../strings.js"
import { Button } from "@mui/material";

function ToSchedule() {
  return (

  <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', }}>

      <p className="Secondary-text" style={{color: '#00008b', textAlign: 'center', fontSize: '1.5em', lineHeight: 2}}>
        <b>לקביעת תור</b>
      <br />
      <b>{strings.phone}</b>
      <br />

      <Button onClick={() => clickToSchedule()}>או לחצו כאן</Button>
      </p>
  </div>
  );
}

function clickToSchedule() {
  window.odoro = {};
  window.odoro.params = {
      clinicId: '<539956256>', // Replace with your actual Clinic ID
      mode: 'public',
      divId: 'odoro' // Optional element id, defaults to 'odoro'
      // Add any additional customization parameters here
  };

  var e = document.createElement("script");
  e.async = true;
  e.src = "//widget.doctorim.co.il/js/widget.js";

  var t = document.getElementsByTagName("script")[0];
  t.parentNode.insertBefore(e, t);

}

export default ToSchedule;