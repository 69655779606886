import '../App.css';
import LogoAndTagline from './LogoAndTagline.js';
import Navbar from './Navbar.js';
import ToSchedule from './ToSchedule.jsx';

function Header() {
  return (
    <div className='Header-combined' style={{display: 'flex', height: '200%'}}>
        <div className='Header' style={{display: 'flex', height: '100%'}}>
            <LogoAndTagline />
            <ToSchedule />
            <Navbar />
        </div>
    </div>
  );
}

export default Header;
